import React from 'react'
import HeroWide from '@widgets/HeroWide'
import ContentLeft from './Hero.Content.Left'
import ContentRight from './Hero.Content.Right'

const Hero = () => {
  return (
    <HeroWide.Wrapper sx={{ py: 3 }}>
      <HeroWide.LeftColumn>
        <ContentLeft />
      </HeroWide.LeftColumn>
      <HeroWide.RightColumn>
        <ContentRight />
      </HeroWide.RightColumn>
    </HeroWide.Wrapper>
  )
}

export default Hero;