import React from 'react'
import { Heading } from 'theme-ui'

const styles = {
  heading: {
    span: {
      color: `omegaDark`
    }
  }
}

const HeroLeft = () => {
  return (
    <Heading variant='h1' sx={styles.heading}>
      Entdecke unsere Kategorien
    </Heading>
  )
}

export default HeroLeft;