import React from 'react'
import { Box } from 'theme-ui'
import Categories from '@widgets/Categories'
import { useBlogCategories } from '@helpers-blog'

const styles = {
  box: {
    borderLeft: [0, `5px solid`, `5px solid`],
    borderLeftColor: `omegaLighter`,
    pl: [0, 4, 5],
    py: [0, 2, 2],
    my: [0, 4, 4]
  }
}

const HeroRight = () => {
  let categories = useBlogCategories()
  // sort the categories in alphabetical order
  categories = categories.sort((a, b) => {
    return a.name.localeCompare(b.name)
  })

  return (
    <Box sx={styles.box}>
      <Categories categories={categories} variant='vertical' omitTitle />
    </Box>
  )
}

export default HeroRight
