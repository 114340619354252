import React from 'react'
import { Layout, Stack, Hero, Main } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import HeroComponent from '../components/Hero/Hero'

const Posts = ({
  data: { posts = {}, featuredPosts = {} },
  ...props
}) => {

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Divider space={3} />
      <Stack effectProps={{ effect: false }}>
        <CardList
          nodes={featuredPosts.nodes}
          limit={3}
          columns={[1, 2, 3]}
          variant={['horizontal-md', 'vertical-cover']}
          title="Neuste Artikel"
          loading='eager'
          omitCategory
        />
      </Stack>
      <Divider space={2} />
      <Stack>
        <CardList
          nodes={featuredPosts.nodes}
          limit={6}
          skip={3}
          columns={[1, 2, 3]}
          variant={['horizontal-md', 'horizontal-aside']}
          loading='eager'
        />
      </Stack>
      <Divider />
      <Hero sx={{ bg: `contentBg` }}>
        <HeroComponent />
      </Hero>
      <Divider />
      {posts.group.length &&
        posts.group.map((group, index) => (
          <React.Fragment key={`${group.categoryName}.list`}>
            {index % 2 === 0 ? (
              <Stack
                title={group.categoryName}
                titleLink={group.nodes[0].category.slug}
              >
                <Main>
                  <CardList
                    nodes={group.nodes}
                    variant={['horizontal-md', 'vertical']}
                    limit={6}
                    columns={[1, 2, 3, 3]}
                    omitCategory
                  />
                </Main>
              </Stack>
            ) : (
              <Stack
                effectProps={{ effect: 'fadeInLeft' }}
                title={group.categoryName}
                titleLink={group.nodes[0].category.slug}
              >
                <Main>
                  <CardList
                    nodes={group.nodes}
                    variant={['horizontal-md', 'horizontal']}
                    limit={6}
                    columns={[1, 1, 2]}
                    omitCategory
                  />
                </Main>
              </Stack>
            )}
            {index !== posts.group.length - 1 && <Divider />}
          </React.Fragment>
        ))}
    </Layout>
  )
}

export default Posts
