import React from 'react'
import { Box } from 'theme-ui'
import { IconContext } from 'react-icons'
import IconButton from '@components/IconButton'
import Section from '@components/Section'
import loadable from '@loadable/component'

const styles = {
  horizontal: {
    display: `flex`,
    flexWrap: `nowrap`,
    overflowX: `auto`,
    width: `auto`,
    scrollBehavior: `smooth`,
    m: -2,
    a: {
      flex: 1,
      minWidth: [`1/3`, `auto`],
      m: 2
    }
  }
}

/**
 * Loads dynamically an Icon form React-Icons
 * @param iconName
 * @returns {null|React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 * @constructor
 */
function DynamicIcon(iconName) {
  if (iconName) {
    const [library, iconComponent] = iconName.split('/')
    if (!library || !iconComponent) return null

    const lib = library.toLowerCase()
    return loadable(() => import(`react-icons/${lib}/index.js`), {
      resolveComponent: el => el[iconComponent]
    })
  } else {
    return null
  }
}

const Categories = ({ variant, categories, ...props }) => {
  return (
    <Section aside={variant === 'vertical'} title='Kategorien' {...props}>
      <IconContext.Provider value={{ size: '1em' }}>
        <Box sx={styles[variant]}>
          {categories &&
            categories.map(({ id, name, slug, totalCount, icon, iconName }) => {
              const buttonProps = {
                key: id,
                name,
                number: totalCount,
                to: slug,
                iconPath: icon,
                Icon:
                  !icon && DynamicIcon(iconName ? iconName : 'Fa/FaArchive'),
                variant
              }

              return totalCount !== 0 && <IconButton {...buttonProps} />
            })}
        </Box>
      </IconContext.Provider>
    </Section>
  )
}

export default Categories

Categories.defaultProps = {
  variant: 'vertical'
}
